$boxShadowMain: 0 2px 15px -5px rgba(0, 0, 0, 0.5);
$boxShadowFlatBottom: 0 -2px 15px -5px rgba(0, 0, 0, 0.5);
$boxShadowSecondary: 0 0 5px rgba(0, 0, 0, 0.5);
$popoverShadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);
$mobileShadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.5);

// =================================================== FONT ============================================//
$regular: 400;
$bolder: 500;
$fontFamily: "Heebo", sans-serif;

// RTL FONT Primary (title, buttons)
$rtlPrimaryFont: "Heebo", sans-serif;
$rtlPrimaryFontWeight: 500;
$rtlPrimaryFontLetterSpacing: 2px;
$rtlPrimaryFontColor: #343434;

// RTL FONT Secondary (content)
$rtlSecondaryFont: "Assistant", sans-serif;
$rtlSecondaryFontWeight: 400;
$rtlSecondaryFontColor: (
  "darker": #343434,
  "lighter": #a2a0a0,
);

// LTR FONT Primary (title, buttons)
$ltrPrimaryFont: "Coolvetica", sans-serif;
$ltrPrimaryFontWeight: 400;
$ltrPrimaryFontLetterSpacing: 3px;
$ltrPrimaryFontColor: #343434;

// LTR FONT Secondary (content)
$ltrSecondaryFont: Helvetica, sans-serif;
$ltrSecondaryFontWeight: 300;
$ltrSecondaryFontColor: (
  "darker": #343434,
  "lighter": #a2a0a0,
);

// Font Sizes
$smallestFontSize: 0.75rem; // 12px
$smallerFontSize: 0.87rem; // 14px
$regularFontSize: 1rem; // 16px
$biggerFontSize: 1.5rem; // 24xp

// ================================================= COLOR ==============================================//
// $primaryColor: #37a6f5;
$primaryColor: #0a53a2;
$secondaryColor: #fff;
$tertiaryColor: #d3d3d3;
$borderColorDarker: #979797;
$textColor: #343434;
$popoverColor: #fcfcfc;
$lighterTextColor: #585858;
$borderColor: #f0f0f0;
$secondaryButtonColor: #f6f6f6;
$clickableTextColor: #78beff;
$effectColor: #e1f3ff;
$popoverTextColor: #989696;
$selectedColor: #00d181;
$infoColor: #b620e0;
$favoriteColor: #fecf6b;

// Utils
// $radius: 22px;
$radius: 3px;

$radius-small: 5px;

$rtl: false !default;
$dir: ltr;

[dir="rtl"] {
  $rtl: true;
  $dir: rtl;
}
