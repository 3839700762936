@use "./mixins";

.MuiFormHelperText-root {
  @include mixins.rtl {
    text-align: right;
  }
}

/* Date Picker Styles - Start */
.MuiPickersDateRangePickerInput-root {
  flex-direction: row !important;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
  @include mixins.rtl {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight:last-child {
  @include mixins.rtl {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlightStart {
  @include mixins.rtl {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd {
  @include mixins.rtl {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child.MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd,
.MuiPickersDateRangeDay-rangeIntervalDayHighlight:last-child.MuiPickersDateRangeDay-rangeIntervalDayHighlightStart {
  border-radius: 50%;
}

.MuiPickersDateRangeDay-root:first-child .MuiPickersDateRangeDay-rangeIntervalDayPreview {
  @include mixins.rtl {
    border-left-color: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    border-right-color: rgba(0, 0, 0, 0.12);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.MuiPickersDateRangeDay-root:last-child .MuiPickersDateRangeDay-rangeIntervalDayPreview {
  @include mixins.rtl {
    border-right-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-left-color: rgba(0, 0, 0, 0.12);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
}

.MuiPickersDateRangeDay-root:first-child .MuiPickersDateRangeDay-rangeIntervalDayPreviewEnd {
  @include mixins.rtl {
    border-radius: 50%;
  }
}

.MuiPickersDateRangeDay-rangeIntervalDayPreview.MuiPickersDateRangeDay-rangeIntervalDayPreviewEnd {
  @include mixins.rtl {
    border-right-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
}

.MuiPickersCalendarHeader-monthTitleContainer {
  @include mixins.rtl {
    margin-left: auto;
    margin-right: unset;
  }
}

.MuiPickersArrowSwitcher-previousMonthButtonMargin {
  @include mixins.rtl {
    margin-right: unset;
    margin-left: 24px;
  }
}

.MuiPickersCalendarHeader-monthText {
  @include mixins.rtl {
    margin-right: unset;
    margin-left: 4px;
  }
}
/* Date Picker Styles - End */
