@font-face {
  font-family: "Coolvetica";
  src: local("Coolvetica"), url("../fonts/coolvetica_rg.ttf") format("truetype");
}

@font-face {
  font-family: "Mada";
  src: local("Mada"), url("../fonts/Mada-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Mada";
  src: local("Mada"), url("../fonts/Mada-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mada";
  src: local("Mada"), url("../fonts/Mada-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
